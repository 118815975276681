// components
import Seo from 'src/components/Seo';
// hooks
import useMetaData from 'src/cms/data/home/useMetaData';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  const metaData = useMetaData();

  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={metaData?.title}
      schema={metaData.schema}
      description={metaData?.description}
    />
  );
};

export { default } from './home';
